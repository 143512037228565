import React from "react"
import { Navbar, Nav } from "react-bootstrap"
import { Link } from "gatsby"
import LogoSVG from "./../assets/svgs/Logo.svg"

interface Props {
  siteTitle: string
  isForLandingPage: boolean
}

const Header: React.FC<Props> = ({
  siteTitle = "",
  isForLandingPage,
}: Props) => (
  <header>
    <Navbar expand={"md"}>
      <Navbar.Brand
        href={"/"}
        id={isForLandingPage ? "landing-brand" : "normal-brand"}
        className="logo-size"
      >
        <LogoSVG />
        <div className="brand-text">{siteTitle}</div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls={"nav-bar-responsive"} />
      <Navbar.Collapse id={"nav-bar-responsive"}>
        <Nav as={"ul"} className={"ml-auto"}>
          <Nav.Item as={"li"}>
            <Link
              to={"/verloop-behandeling"}
              className={"nav-link"}
              activeClassName={"active"}
            >
              Behandelingsverloop
            </Link>
          </Nav.Item>
          <Nav.Item as={"li"}>
            <Link
              to={"/soorten-beugels"}
              className={"nav-link"}
              activeClassName={"active"}
            >
              Soorten Beugels
            </Link>
          </Nav.Item>
          <Nav.Item as={"li"}>
            <Link
              to={"/contact"}
              className={"nav-link"}
              activeClassName={"active"}
            >
              Contact
            </Link>
          </Nav.Item>
          <Nav.Item as={"li"}>
            <Link
              to={"/informed-consent"}
              className={"nav-link"}
              activeClassName={"active"}
            >
              Informed Consent
            </Link>
          </Nav.Item>
          <Nav.Item as={"li"}>
            <Link to={"/faq"} className={"nav-link"} activeClassName={"active"}>
              FAQ
            </Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </header>
)

export default Header
